export default {
  data: () => ({
    loading: true,
    snackbar: {
      show: false,
      message: "",
      color: ""
    }
  }),
  methods: {
    showSnackbar(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    }
  }
};
