<template>
  <v-snackbar v-model="show_s" :color="color" timeout="2000"
    >{{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show_s = false">Zamknij</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarStatus",
  props: {
    show: Boolean,
    message: String,
    color: String
  },
  data: () => ({
    show_s: false
  }),
  methods: {
    showSnackbar() {
      this.show_s = this.$props.show;
    }
  },
  watch: {
    $props: {
      handler() {
        this.show_s = this.$props.show;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
